import React, {useEffect, useState} from 'react';
import {Skeleton} from 'antd';
import {Helmet} from 'react-helmet';
import {buildImageUrl} from '~utils/helper';

const ProductInfo = ({product}) => {
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    if (!product || !product.attributeSets || product.attributeSets.length === 0) return;
    const group = product.attributeSets?.find(x => !x.isCheckoutGroup);
    setAttributes(group?.attributes || []);
  }, [product]);

  if (!product) return <Skeleton />;

  const image =
    product.primaryImageUrl ||
    (Array.isArray(product.imageUrls) && product.imageUrls.length > 0 ? product.imageUrls[0] : undefined);

  return (
    <>
      <Helmet
        meta={[
          {
            name: `og:title`,
            content: product.name,
          },
          {
            name: `og:description`,
            content: product.description,
          },
          {
            name: `og:image`,
            content: buildImageUrl(image, '600x315'),
          },
        ]}
      />
      <div className='product-info'>
        <div className='title'>THÔNG TIN CHI TIẾT</div>
        <div className='border' />
        <div className='p-item'>
          <span className='p-label'>Mã sản phẩm</span>
          <span className='p-value'>{product.productCode}</span>
        </div>
        <div className='border' />
        <div className='p-item'>
          <span className='p-label'>Thương hiệu</span>
          <span className='p-value'>{product.brand?.name}</span>
        </div>
        <div className='border' />
        <div className='p-item'>
          <span className='p-label'>Xuất xứ</span>
          <span className='p-value'>{product.madeIn}</span>
        </div>
        {attributes.map(x => (
          <div key={x.id}>
            <div className='border' />
            <div className='p-item'>
              <span className='p-label'>{x.name}</span>
              <span className='p-value'>{x.values?.map(y => y.value)?.join(', ') || ''}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductInfo;
