import React from 'react';
import {Skeleton} from 'antd';

export default function Placeholder() {
  return (
    <div>
      {Array(5)
        .fill(0)
        .map(x => (
          <div
            key={x}
            style={{
              margin: '15px',
              backgroundColor: '#ffffff',
              width: '230px',
              height: '430px',
              display: 'inline-block',
            }}
          >
            <Skeleton.Image
              style={{
                width: '210px',
                height: '310px',
                margin: '10px',
              }}
              block
            />
            <div
              style={{
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
              }}
            >
              <Skeleton.Input size='small' style={{height: '22px', display: 'block'}} />
              <Skeleton.Input block size='small' style={{height: '19px', display: 'block'}} />
              <Skeleton.Input block size='small' style={{height: '19px', display: 'block'}} />
            </div>
          </div>
        ))}
    </div>
  );
}
