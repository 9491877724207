import React, {useState, useEffect, useRef} from 'react';
import {Input, Form, Tag, Col, Row} from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import Modal from '~utils/modals/modal';
import DateRangeCustomField from './dateRangeCustomField/index';
import QuantitiesField from './quantitiesField';
import {getInvalidDate} from '~actions/cart';
import * as customerRequestActions from '~actions/customerRequest';
import {getTextColor, convertDateToUTC} from '~utils/helper';
import moment from 'moment';
import PageLoader from '~components/page-loader';
import './styles.scss';

const CustomerRequestPopup = props => {
  const refForm = useRef(null);
  const [invalidDates, setInvalidDates] = useState([]);

  const getInvalidRentDate = async () => {
    const res = await props.getInvalidDates({productId: props.product.id, attributes: props.attributes});
    setInvalidDates(res || []);
  };

  useEffect(() => {
    getInvalidRentDate();
  }, []);

  const formatValue = orginValue => ({
    userFullname: orginValue.userFullname,
    userPhoneNo: orginValue.userPhoneNo,
    content: orginValue.content,
    productCode: orginValue.productCode,
    productQuantity: orginValue.productQuantity,
    productAttributes: props.attributes,
    dateFrom: convertDateToUTC(orginValue.dateRental[0]),
    dateTo: convertDateToUTC(orginValue.dateRental[1]),
  });

  const onFinish = async values => {
    const customerRequestValue = formatValue(values);
    const res = await props.saveCustomerRequest(customerRequestValue);
    if (res) {
      await props.onCancel();
    }
  };

  const onSave = () => {
    const {submit} = refForm.current;
    submit();
  };

  const renderWithEmptyHistoryPayment = () => {
    const colorAtribule = _.find(props.attributes, ['type', 'VALUE_TYPE_COLOR']);
    const sizeAtribule = _.find(props.attributes, ['type', 'VALUE_TYPE_STRING']);
    const userFullname = !_.isEmpty(props.user) ? props.user.fullname : null;
    const userPhoneNo = !_.isEmpty(props.user) ? props.user.mobileNo : null;

    return (
      <Modal visible title='Gửi Thông Báo Đến Rentzy' onSave={onSave} onCancel={props.onCancel} width={480}>
        <PageLoader loading={props.loading} />
        <div className='customer-request-content'>
          <Form
            ref={refForm}
            name='CustomerRequestPopup'
            scrollToFirstError
            onFinish={onFinish}
            initialValues={{
              userFullname,
              userPhoneNo,
              content: null,
              productCode: props.product.productCode,
              productQuantity: 1,
              size: sizeAtribule.attributeValueId,
              color: colorAtribule.attributeValueId,
              dateRental: null,
            }}
          >
            <Form.Item
              labelCol={{span: 6}}
              wrapperCol={{span: 18}}
              labelAlign='left'
              label='Họ và Tên'
              name='userFullname'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên của bạn.',
                },
              ]}
            >
              <Input type='text' placeholder='Họ và Tên' />
            </Form.Item>
            <Form.Item
              labelCol={{span: 6}}
              wrapperCol={{span: 18}}
              labelAlign='left'
              label='Số điện thoại'
              name='userPhoneNo'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập số điện thoại của bạn.',
                },
              ]}
            >
              <Input placeholder='Số điện thoại' />
            </Form.Item>
            <Form.Item
              labelCol={{span: 6}}
              wrapperCol={{span: 18}}
              labelAlign='left'
              label='Lịch thuê'
              name='dateRental'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ngày bạn muốn thuê.',
                },
              ]}
            >
              <DateRangeCustomField invalidDates={invalidDates} />
            </Form.Item>
            <Form.Item labelCol={{span: 6}} wrapperCol={{span: 18}} label='Note' name='content' labelAlign='left'>
              <Input.TextArea row={4} type='text' />
            </Form.Item>
            <Row>
              <Col span={11}>
                <Form.Item
                  labelCol={{span: 13}}
                  wrapperCol={{span: 10}}
                  labelAlign='left'
                  label='Mã sản phẩm'
                  name='productCode'
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập mã sản phẩm bạn muốn thuê.',
                    },
                  ]}
                >
                  <Input type='text' placeholder='Mã sản phẩm' />
                </Form.Item>
              </Col>
              <Col span={12} offset={1}>
                <Form.Item label='Số lượng' name='productQuantity'>
                  <QuantitiesField />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item labelCol={{span: 13}} wrapperCol={{span: 11}} label='Size' name='size' labelAlign='left'>
                  <Tag key={sizeAtribule.attributeValueId} className='attribute-item selected-item'>
                    {sizeAtribule.value}
                  </Tag>
                </Form.Item>
              </Col>
              <Col span={12} offset={1}>
                <Form.Item label='Màu sắc' name='color'>
                  <Tag
                    key={colorAtribule.attributeValueId}
                    className='color-item'
                    style={{
                      backgroundColor: colorAtribule.value,
                      color: getTextColor(colorAtribule.value),
                    }}
                  >
                    <div className='selected-color display' />
                  </Tag>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    );
  };
  return <div>{renderWithEmptyHistoryPayment()}</div>;
};

CustomerRequestPopup.defaultProps = {};

CustomerRequestPopup.propTypes = {
  user: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  product: PropTypes.shape({}).isRequired,
  attributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getInvalidDates: PropTypes.func.isRequired,
  saveCustomerRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
CustomerRequestPopup.defaultProps = {
  user: null,
  loading: false,
};
export default connect(
  state => ({
    user: state.user.user,
    loading: state.loader.loading,
  }),
  dispatch => ({
    getInvalidDates: request => dispatch(getInvalidDate(request)),
    saveCustomerRequest: request => dispatch(customerRequestActions.saveCustomerRequest(request)),
  })
)(CustomerRequestPopup);
