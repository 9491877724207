import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import './styles.scss';

const QuantitiesField = props => {
  const increase = () => {
    props.onChange(props.value + 1);
  };

  const decrease = () => {
    const expectedCount = props.value === 1 ? props.value : props.value - 1;
    props.onChange(expectedCount);
  };

  console.log(props);

  return (
    <div className='quantities-count'>
      <span className='count-icon' onClick={decrease}>
        <MinusOutlined className='icon' />
      </span>
      <input min='1' value={props.value} disabled />
      <span className='count-icon' onClick={increase}>
        <PlusOutlined className='icon' />
      </span>
    </div>
  );
};

QuantitiesField.propTypes = {
  // defaut func when use this component in form.item of ant lib
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default QuantitiesField;
