import React, {useState} from 'react';
import {Pagination, Empty} from 'antd';
import moment from 'moment';

import {buildImageUrl} from '~utils/helper';
import Star from '~components/icons/star';
import {ReviewStarTypes} from '~utils/enum';

const rates = Array.from(Array(5).keys());

const ProductReviewer = ({productId}) => {
  const [reviewInfo, setReviewInfo] = useState(null);
  const [pagination, setPagination] = useState({page: 1, limit: 5});
  const [total, setTotal] = useState(0);

  const onPageChange = (page, limit) => {
    setPagination({page, limit});
  };

  const getReviewerInfo = item => {
    return (
      <div key={item.id} className='reviewer-item'>
        <div className='r-avatar'>
          <img src={buildImageUrl(item.account.avatar, '44x44')} alt={item.account.avatar} />
        </div>
        <div className='reviewer-info'>
          <div className='r-name'>{item.account.name}</div>
          <div className='r-star'>
            {rates.map(x => (
              <span key={x} className='rate-item'>
                <Star fill={x < item.rate ? '#FCBAA4' : 'white'} size='17' />
              </span>
            ))}
            <span className='comment-date'>({moment(new Date(item.createdDate)).format('MMM DD, YYYY')})</span>
          </div>
          <p className='r-content'>{item.content}</p>
          {item.imageUrls.length > 0 && (
            <div className='r-image'>
              {item.imageUrls.map(x => (
                <img key={x} className='img-item' src={buildImageUrl(x, '60x60')} alt={x} />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='reviewer'>
      <div className='title'>ĐÁNH GIÁ SẢN PHẨM</div>
      {!reviewInfo ? (
        <Empty style={{marginTop: '20px'}} description='Sản phẩm chưa có đánh giá!' />
      ) : (
        <>
          <div className='sum-reviewer'>
            <div className='total'>
              <div className='rate-number'>{reviewInfo.rate}</div>
              <div className='rate-star'>
                {rates.map(x => (
                  <span key={x} className='rate-item'>
                    <Star fill={x < Number(reviewInfo.rate) ? '#FCBAA4' : 'white'} size='20' />
                  </span>
                ))}
              </div>
              <div className='num-reviewer'>{reviewInfo.total} đánh giá</div>
            </div>
            <div className='total-star'>
              {Object.keys(ReviewStarTypes).map(star => (
                <div key={star} className='rate'>
                  {rates.map(x => (
                    <span key={x} className='rate-item'>
                      <Star fill={x < ReviewStarTypes[star] ? '#FCBAA4' : 'white'} size='17' />
                    </span>
                  ))}
                  <span className='total-item'>{reviewInfo[star]}</span>
                </div>
              ))}
            </div>
          </div>
          <div className='border' />
          <div className='reviewer-comment'>
            {reviewInfo.reviewers.length > 0 && reviewInfo.reviewers.map(x => getReviewerInfo(x))}
            <Pagination
              style={{textAlign: 'right'}}
              current={pagination.page}
              pageSize={pagination.limit}
              total={total}
              onChange={onPageChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProductReviewer;
