import React from 'react';

const CalendarDate = ({fill = '#828282', size = '22'}) => (
  <svg width={size} height={size} viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.5 4C2.94772 4 2.5 4.44772 2.5 5V19C2.5 19.5523 2.94772 20 3.5 20H17.5C18.0523 20 18.5 19.5523 18.5 19V5C18.5 4.44772 18.0523 4 17.5 4H3.5ZM0.5 5C0.5 3.34315 1.84315 2 3.5 2H17.5C19.1569 2 20.5 3.34315 20.5 5V19C20.5 20.6569 19.1569 22 17.5 22H3.5C1.84315 22 0.5 20.6569 0.5 19V5Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.5 0C15.0523 0 15.5 0.447715 15.5 1V5C15.5 5.55228 15.0523 6 14.5 6C13.9477 6 13.5 5.55228 13.5 5V1C13.5 0.447715 13.9477 0 14.5 0Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5 0C7.05228 0 7.5 0.447715 7.5 1V5C7.5 5.55228 7.05228 6 6.5 6C5.94772 6 5.5 5.55228 5.5 5V1C5.5 0.447715 5.94772 0 6.5 0Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.5 9C0.5 8.44771 0.947715 8 1.5 8H19.5C20.0523 8 20.5 8.44771 20.5 9C20.5 9.55228 20.0523 10 19.5 10H1.5C0.947715 10 0.5 9.55228 0.5 9Z'
      fill={fill}
    />
  </svg>
);

export default CalendarDate;
