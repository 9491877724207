import React from 'react';
import {Row, Col} from 'antd';

import {utilities} from '~constants/home';

import './styles.scss';

const Utilities = () => {
  const getUtilityItem = item => {
    return (
      <Row className='item'>
        <Col className='icon' span={7}>
          <div className='logo'>{item.icon}</div>
        </Col>
        <Col className='content' span={14}>
          <div className='u-title'>{item.title}</div>
          <div className='u-content'>{item.content}</div>
        </Col>
      </Row>
    );
  };

  return (
    <Row className='utilities'>
      {utilities.map((x, i) => (
        <Col
          key={x.key}
          xs={24}
          md={12}
          lg={6}
          style={utilities.length - 1 !== i && {borderRight: 'solid 1px #D8D6D6'}}
        >
          {getUtilityItem(x)}
        </Col>
      ))}
    </Row>
  );
};

export default Utilities;
