import React, {useEffect, useState} from 'react';
import {Row, Col, Tag, Space, Radio, message, Tooltip, Alert} from 'antd';
import {CalendarOutlined, CloseOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import {cloneDeep, find, isNil} from 'lodash';
import {navigate} from 'gatsby';
import {useCookies} from 'react-cookie';
import CustomerRequestPopup from './customer-request-popup';
import {initialSaleTypes, dateFormat} from '~constants/product-detail';
import {
  getTextColor,
  toVND,
  formatDate,
  getActualRentalDate,
  getLocalRentalDate,
  isValidRentalDates,
  generateUrlParameters,
} from '~utils/helper';
import Star from '~components/icons/star';
import {addCart, getInvalidDate} from '~actions/cart';
import {generatePrice} from '~utils/com-helper';
import notification, {NotifyTypes} from '~utils/modals/notification';
import RentalDatePicker from '~components/rental-date-picker';
import {CartTypes} from '~utils/enum';
import {getMembershipPriceByTypes} from '~utils/price-helper';
import {membershipTitle} from '~constants/home';
import {get} from '~utils/api';
import './styles.scss';
import moment from 'moment';

const TypeKeys = Object.freeze({
  Rental: 'rental',
  Sale: 'sale',
  Membership: 'member-ship',
});

const PriceAndAttributeInfo = ({
  product,
  review,
  saleInfo,
  discountCodes,
  onAttrChange,
  addNewCart,
  getInvalidDates,
  isGuest,
  consumer,
}) => {
  const [selectedTypeKey, setSelectedTypeKey] = useState(TypeKeys.Rental);
  const [saleTypes, setSaleTypes] = useState(initialSaleTypes);
  const [attributeCombine, setAttributeCombine] = useState({});
  const [rentalDate, setRentalDate] = useState([]);
  const [selectedAttributeIds, setSelectedAttributeIds] = useState({});
  const [variantAttributes, setVariantAttributes] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedAttrs, setSelectedAttrs] = useState([]);
  const [invalidDates, setInvalidDates] = useState([]);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [cookies, setCookie] = useCookies(['cart-items']);
  const [membershipConfigs, setMembershipConfigs] = useState([]);
  const [showCustomerRequestPopup, setShowCustomerRequestPopup] = useState(false);
  const getMembershipConfigsByProduct = async () => {
    const res = await get(
      `/v1/product/membership-config/list?${generateUrlParameters({
        product_ids: [product.id],
        membership_package_id: consumer.membership.membershipPackageId,
      })}`
    );

    setMembershipConfigs(res?.data ?? []);
  };

  useEffect(() => {
    if (consumer?.membership && product.id) {
      getMembershipConfigsByProduct();
    } else {
      setMembershipConfigs([]);
    }
  }, [consumer, product?.id]);

  useEffect(() => {
    if (!product || !product.attributeSets || product.attributeSets.length === 0) return;
    const variantGroup = product.attributeSets?.find(x => x.isCheckoutGroup);
    setVariantAttributes(variantGroup?.attributes || []);
    const [defaultSelectedAttrIds, defaultSelectedAttrs] = variantGroup?.attributes?.reduce(
      (result, item) => {
        const value = item.values?.[0];
        if (!value) return result;

        result[0][item.id] = value.id;
        result[1].push({
          attributeId: item.id,
          attributeValueId: value.id,
          name: item.name,
          value: value.value,
          type: item.valueType,
        });
        return result;
      },
      [{}, []]
    ) ?? [{}, []];
    setSelectedAttributeIds({...defaultSelectedAttrIds});
    setSelectedAttrs([...defaultSelectedAttrs]);
  }, [product]);

  useEffect(() => {
    if (!product) return;
    const saleTypeList = [
      {
        ...saleTypes[0],
        price: toVND(product.rentalPrice),
        disabled: !product.canBeRented,
      },
      // {
      //   ...saleTypes[1],
      //   price: `từ ${toVND(product.membershipPrice)}`,
      //   disabled: !product.canBeRented,
      // },
      {
        ...saleTypes[1],
        price: `${toVND(product.salePrice + (attributeCombine.priceDisparity || 0))}`,
        disabled: !product.canBeSold,
      },
    ];

    setSaleTypes(saleTypeList);
  }, [product, attributeCombine]);

  const getInvalidRentDate = async () => {
    const res = await getInvalidDates({productId: product.id, attributes: selectedAttrs});
    setInvalidDates(res || []);
  };

  useEffect(() => {
    if (
      !product ||
      !product.variants ||
      product.variants.length === 0 ||
      Object.keys(selectedAttributeIds).length < variantAttributes.length
    ) {
      setAttributeCombine({});
      return;
    }

    const item = product.variants.find(
      x => !x.attributesCombination?.find(y => selectedAttributeIds[y.attributeId] !== y.attributeValueId)
    );

    setAttributeCombine(item || {});

    if (!item) {
      setInvalidDates([]);
      return;
    }

    if (onAttrChange) onAttrChange(item.imageUrl);

    if (selectedTypeKey === TypeKeys.Rental) getInvalidRentDate();
  }, [selectedAttributeIds]);

  const getDefaultCount = key => {
    switch (key) {
      case TypeKeys.Rental:
        return product.variants?.reduce((sum, item) => sum + (item.rentableQuantities || 0), 0) ? 1 : 0;
      case TypeKeys.Sale:
        return product.variants?.reduce((sum, item) => sum + (item.salableQuantities || 0), 0) ? 1 : 0;
      default:
        return 1;
    }
  };

  useEffect(() => {
    setCount(getDefaultCount(selectedTypeKey));
  }, [attributeCombine, selectedTypeKey]);

  const handleAttrChange = item => {
    if (selectedAttributeIds[item.attributeId] === item.attributeValueId) {
      delete selectedAttributeIds[item.attributeId];
      setSelectedAttributeIds({...selectedAttributeIds});

      selectedAttrs.splice(
        selectedAttrs.findIndex(x => x.attributeId === item.attributeId),
        1
      );
    } else {
      setSelectedAttributeIds({...selectedAttributeIds, [item.attributeId]: item.attributeValueId});

      const attrIndex = selectedAttrs.findIndex(x => x.attributeId === item.attributeId);
      attrIndex > -1 ? (selectedAttrs[attrIndex] = item) : selectedAttrs.push(item);
    }

    setSelectedAttrs([...selectedAttrs]);
  };

  const SaleTypeExpand = ({item}) => {
    if (!item) return null;
    return (
      <div key={item.key} className='sale-type-expand'>
        {/* {item.key === 'rental' && RentalContent()} */}
        {/* {item.key === 'memberships' && MembershipContent()} */}
        {/* {item.key === 'sale' && SaleContent()} */}
        {/* <div className='btn-add-cart' disabled={isDisabled(item.key)}>
          {item.key !== 'memberships' ? 'THÊM VÀO GIỎ HÀNG' : 'EXPLORE MEMBERSHIP'}
        </div> */}
      </div>
    );
  };

  const parseItemToDateRange = () => {
    if (rentalDate.length === 2) return [rentalDate[0], rentalDate[1]];
    if (rentalDate.length === 1) return [rentalDate[0]];
    return [];
  };

  const handleCloseDate = () => {
    setShowDateRangePicker(false);
  };

  const handleDateChange = dateRange => {
    setRentalDate(dateRange.map(x => new Date(x)));
    if (dateRange.length === 2) handleCloseDate();
  };
  const getQuantities = () => {
    switch (selectedTypeKey) {
      case TypeKeys.Sale: {
        return Object.keys(selectedAttributeIds).length < variantAttributes.length
          ? product.variants.reduce((sum, item) => sum + (item.salableQuantities || 0), 0)
          : attributeCombine.salableQuantities;
      }
      case TypeKeys.Rental:
      default: {
        return Object.keys(selectedAttributeIds).length < variantAttributes.length
          ? product.variants?.reduce((sum, item) => sum + (item.rentableQuantities || 0), 0)
          : attributeCombine.rentableQuantities;
      }
    }
  };

  const mapItem = item => {
    const isExitFeatureBooking = find(invalidDates, range =>
      moment(range.dateTo).local().isSameOrAfter(moment().local())
    );
    const quantities = getQuantities();
    const isOutOfStock = quantities === 0 || isNil(quantities);
    return (
      <div className='sale-type-item'>
        <Row justify='space-between'>
          <Col>
            <div className='type-title'>{item.title}</div>
            <div className='content'>{item.content}</div>
            <div className='price'>{item.price}</div>
          </Col>
          {item.key === TypeKeys.Rental && (
            <Col className='date-ranges'>
              <div className='show-date-ranges' onClick={() => setShowDateRangePicker(!showDateRangePicker)}>
                <div className='show-date'>
                  <div className='from'>
                    {rentalDate.length === 2 ? formatDate(rentalDate[0], dateFormat) : 'Từ ngày'}
                  </div>
                  <div className='line-space'>-</div>
                  <div className='to'>
                    {rentalDate.length === 2 ? formatDate(rentalDate[1], dateFormat) : 'Đến ngày'}
                  </div>
                  {rentalDate.length === 2 && (
                    <div className='remove-date' onClick={() => setRentalDate([])}>
                      <CloseOutlined />
                    </div>
                  )}
                </div>
                <div>
                  <CalendarOutlined />
                </div>
              </div>
              {showDateRangePicker && (
                <div className='drawer-calendar'>
                  <RentalDatePicker
                    rentalDate={parseItemToDateRange()}
                    disableDates={invalidDates}
                    onChange={handleDateChange}
                    checkRange
                    customLeftFooter={
                      isExitFeatureBooking || isOutOfStock ? (
                        <button className='btn-notification' onClick={() => setShowCustomerRequestPopup(true)}>
                          Gửi thông báo đến Rentzy
                        </button>
                      ) : null
                    }
                  />
                </div>
              )}
            </Col>
          )}
        </Row>
      </div>
    );
  };

  const onChange = e => {
    setSelectedTypeKey(e.target.value);
  };

  const isValidQuantities = curCount => {
    switch (selectedTypeKey) {
      case TypeKeys.Sale:
        return curCount <= attributeCombine.salableQuantities;
      case TypeKeys.Membership:
        return false;
      case TypeKeys.Rental:
      default:
        return curCount <= attributeCombine.rentableQuantities;
    }
  };

  const increase = () => {
    if (!isValidQuantities(count + 1)) return;
    setCount(count + 1);
  };

  const decrease = () => setCount(count === 0 ? count : count - 1);

  const onCountChange = e => {
    const {
      target: {value},
    } = e;
    const intValue = parseInt(value);

    if (!isValidQuantities(intValue)) return;

    setCount(Number.isNaN(intValue) ? 0 : intValue);
  };

  const validate = () => {
    if (product.isComing) {
      notification(NotifyTypes.WARNING, {message: 'Vui lòng liên hệ với Rentzy để biết thêm chi tiết cho hàng sắp về'});
      return false;
    }

    if (Object.keys(selectedAttributeIds).length < variantAttributes.length) {
      notification(NotifyTypes.WARNING, {message: 'Vui lòng chọn thuộc tính sản phẩm!'});
      return false;
    }

    if (!getQuantities()) {
      notification(NotifyTypes.WARNING, {message: 'Sản phẩm hiện không có sẵn!'});
      return false;
    }

    if (count <= 0) {
      notification(NotifyTypes.WARNING, {message: 'Vui lòng chọn số lượng sản phẩm!'});
      return false;
    }

    if (selectedTypeKey === TypeKeys.Rental) {
      if (rentalDate.length < 2) {
        notification(NotifyTypes.WARNING, {message: 'Vui lòng chọn lịch thuê trước khi thêm vào giỏ hàng!'});
        return false;
      }
      if (!isValidRentalDates(rentalDate, invalidDates)) {
        notification(NotifyTypes.WARNING, {message: 'Lịch thuê không có sẵn, vui lòng chọn lịch thuê khác!'});
        return false;
      }
    }

    return true;
  };

  const orderNow = actualRentalDate => {
    const cartInfo = {
      isToPayNow: true,
      cartType: selectedTypeKey === TypeKeys.Rental ? CartTypes.Rent : CartTypes.Sale,
      carts: [
        {
          productId: product.id,
          attributes: selectedAttrs,
          quantities: count,
          ...product,
          productName: product.name,
          productImage: product.primaryImageUrl,
          available: true,
          salePrice: product.salePrice + (attributeCombine?.priceDisparity ?? 0),
          rentalPrice: product.rentalPrice + (attributeCombine?.rentalPriceDisparity ?? 0),
          membershipRentalPrice: getMembershipPriceByTypes({...product, productId: product.id}, 0, membershipConfigs),
          variant: attributeCombine,
          dateFrom: actualRentalDate[0] && getLocalRentalDate(actualRentalDate[0], -1),
          dateTo: actualRentalDate[1] && getLocalRentalDate(actualRentalDate[1], 1),
        },
      ],
    };

    localStorage.setItem('cartInfo', JSON.stringify(cartInfo));
    navigate('/checkout/payment');
  };

  const addCartToCookie = actualRentalDate => {
    const cartItems = cookies['cart-items'] || [];
    const itemCookie = {
      cookieId: 1,
      productId: product.id,
      attributes: selectedAttrs,
      quantities: count,
      dateFrom: actualRentalDate[0],
      dateTo: actualRentalDate[1],
    };

    if (cartItems.length > 0) {
      const existedItemIdx = cartItems.findIndex(
        x =>
          x.productId === product.id &&
          x.attributes.filter(
            y =>
              selectedAttrs.findIndex(
                z => z.attributeId === y.attributeId && z.attributeValueId === y.attributeValueId
              ) > -1
          ).length === x.attributes.length &&
          ((selectedTypeKey === TypeKeys.Sale && !x.dateFrom) || (selectedTypeKey === TypeKeys.Rental && x.dateFrom))
      );

      if (existedItemIdx > -1) {
        const selectedItem = cartItems[existedItemIdx];
        if (!isValidQuantities(selectedItem.quantities + count)) {
          message.warning('Sản phẩm đã tồn tại trong giỏ hàng và đã đủ số lượng!');
          return false;
        }

        itemCookie.cookieId = selectedItem.cookieId;
        itemCookie.quantities = selectedItem.quantities + count;
        cartItems[existedItemIdx] = cloneDeep(itemCookie);
      } else {
        const curIndex = cartItems.reduce((max, item) => (item.cookieId > max ? item.cookieId : max), 0);

        itemCookie.cookieId = curIndex + 1;
        cartItems.push(cloneDeep(itemCookie));
      }
    } else {
      cartItems.push(cloneDeep(itemCookie));
    }

    setCookie('cart-items', cartItems, {
      path: '/',
      expires: new Date(new Date().setDate(new Date().getDate() + 30)),
    });

    return true;
  };

  const addToCart = async isOrderNow => {
    if (validate()) {
      const rentalDates = cloneDeep(rentalDate);
      const actualRentalDate =
        selectedTypeKey === TypeKeys.Rental
          ? [getActualRentalDate(rentalDates[0], 1), getActualRentalDate(rentalDates[1], -1)]
          : [];

      if (isOrderNow) {
        orderNow(actualRentalDate);
        return;
      }

      if (isGuest) {
        if (!addCartToCookie(actualRentalDate)) {
          return;
        }
      } else {
        const res = await addNewCart({
          productId: product.id,
          attributes: selectedAttrs,
          quantities: count,
          dateFrom: actualRentalDate[0],
          dateTo: actualRentalDate[1],
        });

        if (!res) return;
      }

      message.success('Đã thêm sản phẩm vào giỏ hàng!');
    }
  };

  const getBtnAction = () => {
    switch (selectedTypeKey) {
      case TypeKeys.Sale:
        return <div>Mua ngay</div>;
      case TypeKeys.Membership:
        return <div>Đăng ký ngay</div>;
      case TypeKeys.Rental:
      default:
        return <div>Thuê ngay</div>;
    }
  };

  const generateMembershipArea = () => {
    if (product.membershipRentalPrice || consumer?.membership)
      return (
        <div className='rental'>
          <div className='rental-price'>
            Giá thuê Membership:{' '}
            {toVND(
              getMembershipPriceByTypes(
                {...product, productId: product.id},
                consumer?.membership?.percentDiscount,
                membershipConfigs
              )
            )}
          </div>
        </div>
      );
    return <div className='membership'>{membershipTitle}</div>;
  };

  return (
    <div className='price-attribute-info'>
      <div className='prod-header'>
        {product.brand && <div className='brand-title'>{product.brand.name}</div>}
        <div hidden={!product.isComing} className='coming-soon'>
          <div className='label'>Hàng sắp về</div>
        </div>
      </div>
      <div className='product-title'>{product.name || 'Product name'}</div>
      <div className='rate-info'>
        <div>
          {Array.from(Array(5).keys()).map(x => (
            <span key={x} className='rate-item'>
              <Star fill={x < review.rate ? '#FCBAA4' : 'white'} size={20} />
            </span>
          ))}
        </div>
        <div className='review-summary'>
          (Xem {review.total} đánh giá) | Đã bán {saleInfo.saledTotal}
        </div>
      </div>
      <div className='price-item'>
        <div className='sale-price'>Giá bán: {generatePrice(product.originalPrice, product.salePrice)}</div>
        <div className='rental'>
          <div className='rental-price'>
            Giá thuê: {generatePrice(product.originalRentalPrice, product.rentalPrice)}
          </div>
        </div>
        {generateMembershipArea()}
      </div>
      <div className='row sale-type'>
        <Radio.Group onChange={onChange} value={selectedTypeKey}>
          <Space direction='vertical'>
            {saleTypes.map(x => (
              <div key={x.key}>
                <Radio key={`sale-type-${x.key}`} value={x.key}>
                  {mapItem(x)}
                </Radio>
                {selectedTypeKey === x.key && <SaleTypeExpand item={x} product={product} />}
              </div>
            ))}
          </Space>
        </Radio.Group>
      </div>
      {variantAttributes.map(x => (
        <div key={x.id} className='attributes'>
          <Row className='attr-item'>
            <Col span={4}>
              <label htmlFor={`attribute-${x.id}`}>{x.name}</label>
            </Col>
            <Col span={20}>
              {x.values &&
                x.values.map(y =>
                  x.valueType === 'VALUE_TYPE_COLOR' ? (
                    <Tag
                      key={y.id}
                      onClick={() =>
                        handleAttrChange({
                          attributeId: x.id,
                          attributeValueId: y.id,
                          name: x.name,
                          value: y.value,
                          type: x.valueType,
                        })
                      }
                      className='color-item'
                      style={{
                        backgroundColor: y.value,
                        color: getTextColor(y.value),
                      }}
                    >
                      <div
                        className={`selected-color hover-color ${selectedAttributeIds[x.id] === y.id ? 'display' : ''}`}
                      />
                    </Tag>
                  ) : (
                    <Tag
                      key={y.id}
                      onClick={() =>
                        handleAttrChange({
                          attributeId: x.id,
                          attributeValueId: y.id,
                          name: x.name,
                          value: y.value,
                          type: x.valueType,
                        })
                      }
                      className={`attribute-item ${selectedAttributeIds[x.id] === y.id ? 'selected-item' : ''}`}
                    >
                      {y.value}
                    </Tag>
                  )
                )}
            </Col>
          </Row>
        </div>
      ))}
      <Row className='attr-count'>
        <Col span={4}>Số lượng</Col>
        <Col span={20} className='p-count'>
          <div className='count'>
            <span className='count-icon' onClick={decrease}>
              <MinusOutlined className='icon' />
            </span>
            <input min='1' value={count} onChange={onCountChange} />
            <span className='count-icon' onClick={increase}>
              <PlusOutlined className='icon' />
            </span>
          </div>
          {selectedTypeKey !== TypeKeys.Membership && (
            <div className='warehouse'>
              Tổng kho:{' '}
              <span className='wh-total'>
                {getQuantities() > 0 ? getQuantities() : <span style={{color: 'red'}}>Hết hàng</span>}
              </span>
            </div>
          )}
        </Col>
      </Row>
      <Row className='discount'>
        <Col span={4}>Mã giảm giá</Col>
        <Col>
          <Space>
            {discountCodes && discountCodes.length > 0 ? (
              discountCodes.map(x => (
                <Tooltip key={x.id} title={x.name}>
                  <Tag className='dicount-item'>{x.publicCode}</Tag>
                </Tooltip>
              ))
            ) : (
              <Alert className='dicount-item' type='info' message='No vouchers available!' />
            )}
          </Space>
        </Col>
      </Row>
      <div className='btn-sale-rental'>
        <button className='btn-add-cart' onClick={() => addToCart(false)}>
          Thêm Vào Giỏ Hàng{' '}
          <span>
            <PlusOutlined />
          </span>
        </button>
        <button className='btn-action' onClick={() => addToCart(true)}>
          {getBtnAction()}
        </button>
      </div>
      {showCustomerRequestPopup && (
        <CustomerRequestPopup
          onCancel={() => setShowCustomerRequestPopup(false)}
          product={product}
          attributes={selectedAttrs}
        />
      )}
    </div>
  );
};

export default connect(
  state => ({
    isGuest: state.user.isGuest,
    consumer: state.user.consumer,
  }),
  dispatch => ({
    addNewCart: request => dispatch(addCart(request)),
    getInvalidDates: request => dispatch(getInvalidDate(request)),
  })
)(PriceAndAttributeInfo);
