/* eslint-disable operator-assignment */
import React, {useRef, useEffect, useState} from 'react';
import {Link} from '@reach/router';
import {Col, Empty, Row} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {get, post} from '~utils/api';
import ArrowRight from '~components/icons/arrow-right';
import ProductItem from '~components/product-item';
import CollectionItem from '~components/collection-item';
import Placeholder from './Placeholder';

import './styles.scss';

const Collection = ({item, type, productId}) => {
  const currentRef = useRef(null);
  const [collections, setCollections] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCollection = async () => {
      const res = await get(`/v1/collection/consumer?pagination.limit=20&pagination.page=1&type=${type}`);
      if (!res) return;
      setCollections(res.items?.filter(x => x.products?.length > 0) || []);
    };

    const getRelatedProducts = async () => {
      if (productId) {
        const res = await get(`/v1/product/${productId}/related-product/list?pagination.limit=20&pagination.page=1`);
        if (!res) return;

        setProducts(res.items || []);
      }
    };

    setLoading(true);

    switch (type) {
      case 'RelatedProduct':
        Promise.all([getRelatedProducts()]).finally(() => setLoading(false));
        break;
      default:
        Promise.all([getCollection()]).finally(() => setLoading(false));
        break;
    }
  }, []);

  const handleClick = direction => {
    let scrollCompleted = 0;
    // eslint-disable-next-line no-var
    var slideVar = setInterval(() => {
      if (direction === 'prev') {
        currentRef.current.scrollLeft = currentRef.current.scrollLeft - window.innerWidth / 15;
      } else {
        currentRef.current.scrollLeft = currentRef.current.scrollLeft + window.innerWidth / 15;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 100) {
        window.clearInterval(slideVar);
      }
    }, 50);
  };

  const isEmpty = collections.length === 0 && products.length === 0 && loading === false;
  const isProductCollection = type === 'RelatedProduct';

  if (isEmpty) return <div />;

  return (
    <div className='div-collection'>
      <Row justify='space-between'>
        <Col xs={24} md={12} lg={18} className='collection-title'>
          {item.title}
        </Col>
        <Col xs={24} md={5} lg={4} dir='rtl'>
          <Link to={item.link}>
            <div className='show-all'>
              <span className='arrow-icon'>
                <ArrowRight />
              </span>
              Xem tất cả
            </div>
          </Link>
        </Col>
      </Row>
      <div className='collection'>
        <div className='prev' onClick={() => handleClick('prev')}>
          <span className='c-prev-icon'>
            <LeftOutlined />
          </span>
        </div>
        <div className='collection-scroll' ref={currentRef}>
          {loading && <Placeholder />}
          {!loading &&
            (isProductCollection
              ? products?.map((x, i) => (
                  <ProductItem key={x.id} item={x} isFirst={i === 0} isLast={i === products.length - 1} />
                ))
              : collections?.map((x, i) => (
                  <CollectionItem key={x.id} item={x} isFirst={i === 0} isLast={i === collections.length - 1} />
                )))}
        </div>
        <div className='next' onClick={() => handleClick('next')}>
          <span className='c-next-icon'>
            <RightOutlined />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Collection;
