import React from 'react';
import {Link} from 'gatsby';
import {Row, Col, Skeleton} from 'antd';
import {buildImageUrl} from '~utils/helper';

import './styles.scss';

const SmallBanner = ({banners}) => {
  if (!banners) return <Skeleton />;

  if (banners.length === 0) return null;

  return (
    <Row className='sm-banner' justify='center' gutter={[20, 20]}>
      {banners.map(x => (
        <Col key={x.id} span={8} className='banner-horizontal'>
          <Link to='/'>
            <img key={x.id} src={buildImageUrl(x.imageUrl, '410x250')} alt={x.name} title={x.caption} />
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default SmallBanner;
