import {Link} from 'gatsby';
import React, {useState, useEffect} from 'react';

import {LazyLoadImage} from 'react-lazy-load-image-component';
import Favorite from '~components/icons/favorite';
import {buildImageUrl, parseNameToUrl, toVND} from '~utils/helper';
import {post, remove} from '~utils/api';
import {generatePrice} from '~utils/com-helper';
import {membershipTitle} from '~constants/home';

import './styles.scss';

const CollectionItem = ({item}) => {
  const products = item.productItems || [];

  const maxprod = products[0];

  // if has only 1 products for collection,
  // will not flip picture or show price min.
  const minprod = products[1] || null;

  const [product, setProduct] = useState(null);

  useEffect(() => {
    setProduct(item.productItems ? item.productItems[0] : null);
  }, [item]);

  const getLink = () => {
    let url = '/';
    if (item.categories) {
      url += `${parseNameToUrl(item.categories[0].name)}.cat.${item.categories[0].categoryId}`;
    } else {
      url += 'collections';
    }

    const searchParams = new URLSearchParams();
    if (item.attributes) {
      searchParams.set(
        'attrs',
        item.attributes
          .map(x => `${x.attributeId}:${x.attributeValues?.map(y => y.attributeValueId).join(',') || '1'}`)
          .join('-')
      );
    }

    if (item.products && item.products.length > 0) {
      if (item.products.length === 1) {
        return `/product/${parseNameToUrl(item.products[0].name)}.prod.${item.products[0].productId}`;
      }

      return `/collections?id=${item.id}`;
    }

    return `${url}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
  };

  const displayPrice = (minprodPrice, maxprodPrice, isSale = true) => {
    if (minprodPrice) {
      return (
        <>
          {toVND(minprodPrice || 0)} ~ {toVND(maxprodPrice || 0)}
        </>
      );
    }

    return isSale
      ? generatePrice(maxprod.displayPrice, maxprod.salePrice)
      : generatePrice(maxprod.originalRentalPrice, maxprod.rentalPrice);
  };

  const handleAddFavorite = async () => {
    product.isLike
      ? await remove('/v1/product/favorite-product', {id: product.productId})
      : await post('/v1/product/favorite-product', {id: product.productId});

    setProduct({...product, isLike: !product.isLike});
  };

  return (
    <div className='collection-item'>
      {!minprod && (
        <div className={`favorite-area ${product?.isLike ? 'favorited' : ''}`} onClick={handleAddFavorite}>
          <Favorite fill='white' size={16} />
        </div>
      )}
      <Link to={getLink()}>
        <div className='collection-image'>
          <div className={`collection-image-item ${minprod ? 'front' : ''}`}>
            <LazyLoadImage
              key={item.id}
              src={buildImageUrl(maxprod.imageUrl, '416x616')}
              alt={maxprod.name}
              title={maxprod.name}
              width={208}
              height={308}
            />
          </div>
          {minprod && (
            <div className='collection-image-item back'>
              <LazyLoadImage
                key={item.id}
                src={buildImageUrl(minprod.imageUrl, '416x616')}
                alt={minprod.name}
                title={minprod.name}
                width={208}
                height={308}
              />
            </div>
          )}
        </div>
        <div className='item-content'>
          <div className='item-title' title={item.name}>
            {item.name}
          </div>
          <div className='item-price'>
            Giá bán: <span className='item-sale-price'>{displayPrice(minprod?.salePrice, maxprod?.salePrice)}</span>
          </div>
          <div className='item-price'>
            Giá thuê:{' '}
            <span className='item-sale-price'>{displayPrice(minprod?.rentalPrice, maxprod?.rentalPrice, false)}</span>
          </div>
          <div className='item-price membership'>{membershipTitle}</div>
        </div>
      </Link>
    </div>
  );
};

export default CollectionItem;
