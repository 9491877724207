import React, {useState, useEffect} from 'react';
import {Row, Col, Modal} from 'antd';
import {PlayCircleOutlined} from '@ant-design/icons';
import {Carousel} from 'react-responsive-carousel';
import {connect} from 'react-redux';
import {getYoutubeThumbnail, buildImageUrl} from '~utils/helper';
import {youtubePrefix, youtubeEmbedPrefix} from '~constants/product-detail';
import {post, remove} from '~utils/api';
import InstagramCircle from '~components/icons/instagram-circle';
import Favorite from '~components/icons/favorite';
import TiktokCircle from '~components/icons/tiktok-circle';
import FacebookCircle from '~components/icons/facebook-circle';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles.scss';

const ImagesPopup = ({imageUrls, onClose}) => {
  const handleCancel = () => {
    onClose();
  };
  return (
    <Modal visible onCancel={handleCancel} footer={false} width={600}>
      <div className='image-popup'>
        <Carousel autoPlay showStatus={false}>
          {imageUrls.map((x, i) => (
            <div key={i}>
              <img className='image-item' src={buildImageUrl(x, '_x600')} alt={x} />
            </div>
          ))}
        </Carousel>
      </div>
    </Modal>
  );
};

const ShowImages = ({href, productId, videoUrl, imageUrls, attrImageUrl, user, isFavorite}) => {
  const [isLike, setIsLike] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  useEffect(() => {
    setIsLike(isFavorite);
  }, [isFavorite]);

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChange = item => {
    setSelectedItem(item.id);
  };

  const handleLikeIcon = async () => {
    const response = isLike
      ? await remove('/v1/product/favorite-product', {id: productId})
      : await post('/v1/product/favorite-product', {id: productId});

    setIsLike(!!response && !isLike);
  };

  const next = () => (selectedItem === imageUrls.length ? setSelectedItem(0) : setSelectedItem(selectedItem + 1));

  const prev = () => (selectedItem === 0 ? setSelectedItem(imageUrls.length) : setSelectedItem(selectedItem - 1));

  useEffect(() => {
    const index = imageUrls.findIndex(x => x === attrImageUrl);
    if (index > -1) setSelectedItem(index);
  }, [attrImageUrl, imageUrls]);

  useEffect(() => {
    setSelectedItem(videoUrl ? -1 : 0);
  }, [videoUrl]);

  const handleShare = socialUrl => {
    if (window) {
      const width = window.innerWidth * 0.66;
      // define the height in
      const height = (width * window.innerHeight) / window.innerWidth;
      // Ratio the hight to the width as the user screen ratio
      window.open(
        `${socialUrl}${href}`,
        'newwindow',
        `width=500, height=400, top=${(window.innerHeight - height) / 2}, left=${(window.innerWidth - width) / 2}`
      );
    }
    return false;
  };

  return (
    <div className='show-image'>
      <Row>
        <Col span={4}>
          <div className='image-list'>
            {videoUrl && (
              <div>
                <div
                  style={{
                    backgroundImage: `url(${getYoutubeThumbnail(videoUrl)})`,
                  }}
                  className={`video-item ${selectedItem === -1 ? 'selected-image' : ''}`}
                  onClick={() => setSelectedItem(-1)}
                >
                  <PlayCircleOutlined className='play-icon' />
                </div>
              </div>
            )}
            {imageUrls.map(
              (x, i) =>
                i < 6 && (
                  <div
                    key={i}
                    className={`image-item ${selectedItem === i ? 'selected-image' : ''}`}
                    onClick={() => handleChange({id: i, img: x})}
                  >
                    <img src={buildImageUrl(x, '67x100')} alt={x} />
                  </div>
                )
            )}
            {imageUrls.length > 6 && (
              <div className='more-image' onClick={onOpenModal}>
                <span>+ {imageUrls.length - 6}</span>
              </div>
            )}
          </div>
        </Col>
        <Col span={20}>
          {selectedItem === -1 ? (
            <div className='show-video'>
              <iframe
                title='Youtube video'
                height='100%'
                width='100%'
                src={`${youtubeEmbedPrefix}/${videoUrl.substring(youtubePrefix.length)}?autoplay=1&mute=1`}
                onClick={onOpenModal}
              />
            </div>
          ) : (
            <Carousel
              className='big-image'
              showThumbs={false}
              showIndicators={false}
              selectedItem={selectedItem}
              showStatus={false}
            >
              {imageUrls.map((x, i) => (
                <div className='image-item' key={i} onClick={onOpenModal}>
                  <img className='image' src={buildImageUrl(x, '_x640')} alt={x} />
                </div>
              ))}
            </Carousel>
          )}
        </Col>
      </Row>
      <Row justify='center'>
        <Col className='share-like'>
          <div className='sl-item'>Chia sẻ</div>
          <div
            className='sl-item'
            style={{paddingTop: '7px'}}
            onClick={() => handleShare('http://www.facebook.com/sharer.php?u=')}
          >
            <FacebookCircle />
          </div>
          <div className='sl-item' style={{paddingTop: '7px'}}>
            <InstagramCircle size='24' />
          </div>
          <div className='sl-item' style={{paddingTop: '7px'}}>
            <TiktokCircle size='24' />
          </div>
          <div className='sl-item'>
            <div className={`favorite ${isLike ? 'favorited' : ''}`} onClick={handleLikeIcon}>
              Yêu thích
              <span>
                <Favorite size={16} />
              </span>
            </div>
          </div>
        </Col>
      </Row>
      {openModal && <ImagesPopup imageUrls={imageUrls} onClose={handleCloseModal} />}
    </div>
  );
};

export default connect(state => ({
  user: state.user.user,
}))(ShowImages);
