import * as ApiService from '~utils/api';
import {loaderActionType} from './loader';
import notification, {NotifyTypes} from '~utils/modals/notification';

const prefix = 'CUSTOMER_REQUEST';
export const actionTypes = {
  SAVE_CUSTOMER_REQUEST_SUCCESS: `${prefix}_GET_LIST_CITY_SUCCESS`,
  ERROR: `${prefix}_ERROR`,
};

export const saveCustomerRequest = customerRequest => async dispatch => {
  try {
    dispatch({type: loaderActionType.SHOW_LOADER});
    const response = await ApiService.postRequest('/v1/order-schedule', customerRequest);
    if (response && (response.status === 200 || response.status === 201 || response.status === 409)) {
      dispatch({type: actionTypes.SAVE_CUSTOMER_REQUEST_SUCCESS});
      notification(NotifyTypes.SUCCESS, {
        message: 'Yêu cầu về lịch thuê của bạn đã được gửi đến nhân viên của Rentzy.',
      });
      return await response.json();
    }
    ApiService.handleErrorException(response);
  } catch (error) {
    dispatch({type: actionTypes.ERROR, payload: error});
  } finally {
    dispatch({type: loaderActionType.HIDE_LOADER});
  }
  return null;
};
