import React from 'react';
import {Modal} from 'antd';

export default ({title, visible, onSave, onCancel, onAfterClose, width, children}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onSave}
      onCancel={onCancel}
      afterClose={onAfterClose}
      okText='SAVE'
      cancelText='CANCEL'
      width={width}
    >
      {children}
    </Modal>
  );
};
