import React, {useEffect, useState} from 'react';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {startOfDay} from '~utils/helper';
import notification, {NotifyTypes} from '~utils/modals/notification';

import './styles.scss';

const WeeksVN = ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'];
const Months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

const RentalDatePicker = ({
  rentalDate,
  disableDates = [],
  checkRange = false,
  onChange,
  isSelect = true,
  customLeftFooter = null,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendar, setCalendar] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  useEffect(() => {
    const daysInMonth = (y, m) => new Date(y, m + 1, 0).getDate();
    const numberToArrayDates = (n, y, m) => Array.from({length: n}, (_, i) => i + 1).map(x => new Date(y, m, x));

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    let prevCalendar = numberToArrayDates(daysInMonth(year, month - 1), year, month - 1);
    const nowCalendar = numberToArrayDates(daysInMonth(year, month), year, month);
    let nextCalendar = numberToArrayDates(daysInMonth(year, month + 1), year, month + 1);

    const firstDay = new Date(year, month, 1).getDay() ? new Date(year, month, 1).getDay() - 1 : 6;
    prevCalendar = prevCalendar.slice(prevCalendar.length - firstDay, prevCalendar.length);
    nextCalendar = nextCalendar.slice(0, 42 - [...prevCalendar, ...nowCalendar].length);
    setCalendar([...prevCalendar, ...nowCalendar, ...nextCalendar]);
  }, [currentDate]);

  useEffect(() => {
    if (rentalDate) setDateRange(rentalDate);
  }, []);

  const isDisableDate = _date => {
    _date.setHours(0, 0, 0, 0);
    const isPastDate = _date < new Date().setHours(0, 0, 0, 0);

    if (
      disableDates.length > 0 &&
      !disableDates.every(x => !(_date >= new Date(x.dateFrom) && _date <= new Date(x.dateTo)))
    ) {
      return true;
    }

    if (dateRange.length === 1) {
      const startDate = new Date(dateRange[0].toDateString());
      const endDate = new Date(dateRange[0].toDateString());
      const dateOf2Week = 14; // max date range for rental
      startDate.setDate(startDate.getDate() + dateOf2Week);
      endDate.setDate(endDate.getDate() - dateOf2Week);
      return isPastDate || _date >= startOfDay(startDate, true) || _date <= startOfDay(endDate, true);
    }

    return isPastDate;
  };

  const isToday = _date => _date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);

  const isNotCurrentMonth = _date => _date.getMonth() !== currentDate.getMonth();

  const isIncludeDisabledDates = (startDate, endDate) => {
    return !disableDates.every(x => !(startDate <= new Date(x.dateFrom) && endDate >= new Date(x.dateTo)));
  };

  const onPickDate = (_date, e) => {
    e.preventDefault();
    switch (dateRange.length) {
      case 0:
      case 2:
        setDateRange([_date]);
        break;
      case 1: {
        const clone = [...dateRange];
        if (_date.getTime() === clone[0].getTime()) {
          setDateRange([]);
          return;
        }
        if (
          (clone[0] < _date && isIncludeDisabledDates(clone[0], _date)) ||
          (clone[0] > _date && isIncludeDisabledDates(_date, clone[0]))
        )
          clone[0] = _date;
        else {
          if (
            checkRange &&
            Math.abs(startOfDay(clone[0], true).getTime() - startOfDay(_date, true).getTime()) < 2 * 24 * 3600 * 1000
          ) {
            notification(NotifyTypes.ERROR, {message: 'Vui lòng chọn ít nhất 3 ngày liên tiếp!'});
            return;
          }
          if (clone[0] > _date) {
            clone.unshift(_date);
          } else {
            clone.push(_date);
          }
        }
        setDateRange(clone);
        break;
      }
      default:
        break;
    }
  };

  const onMonthChange = type => {
    const date = new Date(currentDate);
    if (type === 'prev') {
      date.setMonth(date.getMonth() - 1);
      setCurrentDate(date);
    }
    if (type === 'next') {
      date.setMonth(date.getMonth() + 1);
      setCurrentDate(date);
    }
  };

  const getDateColor = _date => {
    if (dateRange[0] && startOfDay(dateRange[0], true).getTime() === startOfDay(_date, true).getTime())
      return 'date-send';
    if (dateRange[1] && startOfDay(dateRange[1], true).getTime() === startOfDay(_date, true).getTime())
      return 'date-receive';
    if (dateRange.length === 2 && dateRange[0] < _date && dateRange[1] > _date) {
      return 'date-range';
    }
    return '';
  };

  return (
    <div className={isSelect ? 'rental-date-select' : ''}>
      <div className='rental-date-picker'>
        <div className='header'>
          <div>
            Tháng {Months[currentDate.getMonth()]}, {currentDate.getFullYear()}
          </div>
          <div className='btn-change-month'>
            <button aria-label='Prev' onClick={() => onMonthChange('prev')}>
              <LeftOutlined style={{color: 'black', fontSize: 16}} />
            </button>
            <button aria-label='Next' onClick={() => onMonthChange('next')}>
              <RightOutlined style={{color: 'black', fontSize: 16}} />
            </button>
          </div>
        </div>
        <div className='weeks'>
          {WeeksVN.map(item => (
            <div key={item}>{item}</div>
          ))}
        </div>
        <div className='dates'>
          {calendar.map(item => (
            <button
              key={item}
              className={`${isDisableDate(item) && 'disable'} ${isToday(item) && 'today'} ${
                isNotCurrentMonth(item) && 'not-current-month'
              } ${getDateColor(item)}`}
              disabled={isDisableDate(item)}
              onClick={e => onPickDate(item, e)}
            >
              {item.getDate()}
            </button>
          ))}
        </div>
      </div>
      <div className='helper'>
        <div>
          <div className='helper-item'>
            <div className='square today' />
            <div>Hôm nay</div>
          </div>
          <div className='helper-item'>
            <div className='square date-range' />
            <div>Ngày khách thuê</div>
          </div>
        </div>
        <div>
          <div className='helper-item'>
            <div className='square date-send' />
            <div>Ngày khách nhận hàng</div>
          </div>
          <div className='helper-item'>
            <div className='square date-receive' />
            <div>Ngày khách trả hàng</div>
          </div>
        </div>
      </div>
      <div className='footer'>
        <div className='text-left'>{customLeftFooter}</div>
        <div className='text-right'>
          <button className='btn-apply' onClick={() => onChange(dateRange)}>
            Áp dụng
          </button>
        </div>
      </div>
    </div>
  );
};

export default RentalDatePicker;
