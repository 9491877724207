import React from 'react';
import {Skeleton} from 'antd';
import {buildImageUrl} from '~utils/helper';

const BrandDetail = ({brand}) => {
  if (!brand) return <Skeleton />;

  return (
    <div className='brand'>
      <div className='title'>THƯƠNG HIỆU</div>
      <div className='border' />
      <div className='br-logo' style={{backgroundImage: `url(${buildImageUrl(brand.logo, '_x80')})`}} />
      <div className='br-name'>{brand.name}</div>
      <div className='br-content'>{brand.description}</div>
    </div>
  );
};

export default BrandDetail;
