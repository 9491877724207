import React, {useState} from 'react';
import PropTypes from 'prop-types';
import RentalDatePicker from '~components/rental-date-picker';
import CalendarDate from '~components/icons/calendar-date';

import {formatDate} from '~utils/helper';
import './styles.scss';

const DateRangeCustomField = props => {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [rentalDate, setRentalDate] = useState([]);

  const parseItemToDateRange = () => {
    if (rentalDate.length === 2) return [rentalDate[0], rentalDate[1]];
    if (rentalDate.length === 1) return [rentalDate[0]];
    return [];
  };

  const handleDateChange = dateRange => {
    const selectDateRange = dateRange.map(x => new Date(x));
    setRentalDate(selectDateRange);
    if (dateRange.length === 2) {
      setShowDateRangePicker(false);
      props.onChange(selectDateRange);
    }
  };
  const clearRentalDate = () => {
    setRentalDate([]);
    props.onChange(null);
  };
  return (
    <div className='date-ranges-field'>
      <div className='show-date-ranges' onClick={() => setShowDateRangePicker(!showDateRangePicker)}>
        <div className='from'>
          <CalendarDate />
          {rentalDate.length === 2 ? formatDate(rentalDate[0]) : 'Từ ngày'}
        </div>
        <div className='to'>{rentalDate.length === 2 ? formatDate(rentalDate[1]) : 'Đến ngày'}</div>
      </div>
      {showDateRangePicker && (
        <div className='drawer-calendar'>
          <RentalDatePicker
            rentalDate={parseItemToDateRange()}
            disableDates={[]}
            onChange={handleDateChange}
            checkRange
          />
        </div>
      )}
    </div>
  );
};

DateRangeCustomField.propTypes = {
  // defaut func when use this component in form.item of ant lib
  onChange: PropTypes.func.isRequired,
};
export default DateRangeCustomField;
