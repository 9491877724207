import {Empty} from 'antd';
import React, {useState} from 'react';

import ArrowRight from '~components/icons/arrow-right';

const testData = [
  {
    id: 1,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?',
    reply:
      'Test reply ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna',
  },
  {
    id: 2,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?',
    reply:
      'Test reply ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna',
  },
  {
    id: 3,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?',
    reply:
      'Test reply ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna',
  },
  {
    id: 4,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?',
    reply:
      'Test reply ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna',
  },
  {
    id: 5,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?',
    reply:
      'Test reply ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna',
  },
];

const QuestionAnswer = ({productId}) => {
  const [questions, setQuestions] = useState([]);
  const [total, setTotal] = useState(0);
  const [replyContent, setReplyContent] = useState({});
  const [pagination, setPagination] = useState({page: 1, limit: 5});

  // useEffect(() => {
  //   const getQuestions = async () => {
  //     // const res = await get(
  //     //   `/v1/product/${productId}/comment/list?pagination.page=${pagination.page}&pagination.limit=${pagination.limit}`
  //     // );
  //     // if (!res) return;

  //     setTotal(10);
  //     setQuestions([]);
  //   };

  //   getQuestions();
  // }, [pagination]);

  const handleReplyComment = (e, id) => {
    setReplyContent({...replyContent, [id]: e.target.value});
  };

  const getQuestionItem = item => (
    <div key={item.id}>
      <div className='border' />
      <div className='q-content'>{item.content}</div>
      <div className='q-detail'>
        Xem chi tiết{' '}
        <span>
          <ArrowRight size='14' />
        </span>
      </div>
    </div>
  );

  const onPageChange = (page, limit) => {
    setPagination({page, limit});
  };

  return (
    <div className='question-answer'>
      <div className='title'>HỎI ĐÁP</div>
      <div>
        {questions.length > 0 ? (
          <div>
            {questions.map(item => getQuestionItem(item))}
            <div className='q-show-more'>Xem thêm</div>
          </div>
        ) : (
          <Empty style={{marginTop: '20px'}} description='Sản phẩm chưa có hỏi đáp!' />
        )}
      </div>
    </div>
  );
};

export default QuestionAnswer;
