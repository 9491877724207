import {Empty} from 'antd';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import gfm from 'remark-gfm';

const Description = ({description}) => {
  return (
    <div className='description'>
      <div className='title'>MÔ TẢ SẢN PHẨM</div>
      <div style={{margin: '20px 0'}}>
        {!description ? (
          <Empty description='Sản phẩm chưa có mô tả chi tiết!' />
        ) : (
          <ReactMarkdown remarkPlugins={[gfm]}>{description}</ReactMarkdown>
        )}
      </div>
    </div>
  );
};

export default Description;
