import React, {useEffect} from 'react';
import Layout from '~components/layout';
import ProductDetail from '~containers/product-detail';
import {parseRouter} from '~utils/helper';
import {RouterKeys} from '~utils/enum';
import {firebaseAnalytics} from '~utils/firebase';

const Product = props => {
  useEffect(() => {
    firebaseAnalytics.logEvent('Product_detail_visited');
  }, []);

  const {
    params: {productLink},
  } = props;
  const product = parseRouter(productLink, RouterKeys.Product);
  return (
    <Layout>
      {/* <Seo title={product.name} /> */}
      <ProductDetail {...props} product={product} />
    </Layout>
  );
};

export default Product;
