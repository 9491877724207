import React from 'react';

const ArrowRight = ({fill = 'black', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 24 10'
    width={size}
    height={size}
    fill={fill}
  >
    <path d='M23.0676 4.99286L18.8178 0.757385L17.406 2.17398L19.2414 4.00319L0.932347 4.00119L0.932129 6.00119L19.2368 6.00319L17.4153 7.83081L18.8319 9.24264L23.0676 4.99286Z' />
  </svg>
);

export default ArrowRight;
