import React, {useState, useEffect} from 'react';
import {Breadcrumb, Row, Col, Skeleton, Result} from 'antd';
import {Link, navigate} from 'gatsby';
import {get} from '~utils/api';
import Collection from '~components/collection';
import {review, saleInfo} from '~components/test-data';
import {BannerTypes, CollectionTypes} from '~utils/enum';
import Seo from '~components/seo';
import Utilities from '~components/utitlities';
import SmallBanner from '~components/small-banner';
import PriceAndAttributeInfo from './price-attribute-info';
import Description from './description';
import BrandDetail from './brand-detail';
import ProductInfo from './product-info';
import ProductReviewer from './product-reviewer';
import ShowImages from './show-images';
import QuestionAnswer from './question-answer';

import './styles.scss';

const brand = {
  logo: 'https://www.thebrandingjournal.com/wp-content/uploads/2019/05/chanel_logo_the_branding_journal.jpg',
  content:
    'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
};

const ProductDetail = ({location, product}) => {
  const [productDetail, setProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [attrImageUrl, setAttrImageUrl] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [banners, setBanners] = useState(null);
  const [error, setError] = useState(false);
  const [promotion, setPromotion] = useState([]);

  useEffect(() => {
    const getProduct = async () => {
      setError(false);
      const res = await get(`/v1/product/product-detail/${product.id}`);
      if (!res) {
        setError(true);
        return;
      }

      setProduct(res);
      setSeoTitle(res.name);
      setCategories(res.categories || []);
      let allImageUrls = res.imageUrls || [];
      if (res.primaryImageUrl) allImageUrls.unshift(res.primaryImageUrl);
      const attrs = res.attributeSets?.find(x => x.isCheckoutGroup)?.attributes ?? 0;
      if (res.variants && res.variants.length > 0 && attrs.length > 0) {
        allImageUrls = allImageUrls.concat(
          res.variants.reduce((result, item) => {
            const isExisted = item.attributesCombination
              ? item.attributesCombination.filter(
                  x =>
                    x.attributeId === item.attributeId && x.values.findIndex(y => y.id === item.attributeValueId) > -1
                ) === item.attributesCombination.length
              : false;
            if (isExisted && item.imageUrl) result.push(item.imageUrl);
            return result;
          }, [])
        );
      }
      setImageUrls(allImageUrls);
    };
    const getPromotion = async () => {
      setError(false);
      const res = await get(`/v1/promotion/consumer`);
      if (!res) {
        setError(true);
        return;
      }
      setPromotion(res?.promotions);
    };

    Promise.all([getProduct(), getPromotion()]);
  }, [product]);

  useEffect(() => {
    // const getProductList = async () => {
    //   const res = await get(`/v1/product/${product.id}/related-product/list?pagination.limit=20&pagination.page=1`);
    //   if (!res) return;

    //   setRelatedProducts(res.items || []);
    // };

    const getSmallBanners = async () => {
      const res = await get(
        `/v1/banner/list?pagination.limit=3&pagination.page=1&&filter=RUNNING_BANNER&type=${BannerTypes.Small}`
      );
      if (!res) return;

      setBanners(res.banners || []);
    };

    // if (product) Promise.all([getProductList(), getSmallBanners()]);
    if (product) getSmallBanners();
  }, [product]);

  const handleAttrChange = imageUrl => {
    if (imageUrl) setAttrImageUrl(imageUrl);
  };

  if (!productDetail && !error) return <Skeleton />;

  if (error)
    return (
      <Result
        className='not-found'
        status='warning'
        title={404}
        subTitle='Sản phẩm này không tồn tại!'
        extra={
          <div className='back-home' onClick={() => navigate('/home')}>
            <span className='btn'>Về trang chủ</span>
          </div>
        }
      />
    );

  return (
    <div className='product-detail'>
      {seoTitle && <Seo title={seoTitle} />}
      <Row justify='center' className='category-path com-bg-gray'>
        <Col xs={24} md={23} lg={21}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item>
              <Link to='/home'>Trang chủ</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/all-products'>Sản phẩm</Link>
            </Breadcrumb.Item>
            {categories.map(x => (
              <Breadcrumb.Item key={`category-path-${x.link}`}>
                <Link to={x.link}>{x.name}</Link>
              </Breadcrumb.Item>
            ))}
            <Breadcrumb.Item>{productDetail.name}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={24} md={23} lg={21}>
          <Row justify='center' className='component'>
            <Col span={12}>
              <ShowImages
                href={location.href}
                productId={productDetail.id}
                videoUrl={productDetail.videoUrl}
                imageUrls={imageUrls}
                attrImageUrl={attrImageUrl}
                isFavorite={productDetail.isLike}
              />
            </Col>
            <Col span={11} offset={1}>
              <PriceAndAttributeInfo
                product={productDetail}
                review={review}
                saleInfo={saleInfo}
                discountCodes={promotion}
                onAttrChange={handleAttrChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={24} md={23} lg={21}>
          <Utilities />
        </Col>
      </Row>
      <Row justify='center' className='com-padding com-bg-gray'>
        <Col xs={24} md={23} lg={21}>
          <Row>
            <Col span={17}>
              <div className='component'>
                <div className='item'>
                  <Description description={productDetail.description} />
                </div>
                <div className='item'>
                  <ProductReviewer productId={productDetail.id} />
                </div>
              </div>
            </Col>
            <Col span={7}>
              <div className='component'>
                <div className='item'>
                  <BrandDetail brand={productDetail.brand || brand} />
                </div>
                <div className='item'>
                  <ProductInfo product={productDetail} />
                </div>
                <div className='item'>
                  <QuestionAnswer productId={productDetail.id} />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify='center' className='com-padding'>
        <Col xs={24} md={23} lg={21}>
          <SmallBanner banners={banners} />
        </Col>
      </Row>
      <Row justify='center' className='com-padding com-bg-gray'>
        <Col xs={24} md={23} lg={21}>
          <Collection
            item={{title: 'Sản Phẩm Tương Tự', link: '/related-product'}}
            type='RelatedProduct'
            productId={product?.id}
          />
        </Col>
      </Row>
      <Row justify='center' className='com-padding'>
        <Col xs={24} md={23} lg={21}>
          <Collection
            item={{title: 'Hàng Mới Về', link: '/new-arrival?sort=newest'}}
            type={CollectionTypes.NewArrival}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ProductDetail;
